import { isPlatformBrowser } from '@angular/common';
import { Component, inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs';

import { AuthService } from '@ultra/core/auth';
import { ClientFeatureService } from '@ultra/core/services';
import { NavbarTab } from '@ultra/shared/modules/navbar/interfaces';

@Component({
  selector: 'ultra-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private authService = inject(AuthService);
  private router = inject(Router);
  private clientFeatureService = inject(ClientFeatureService);
  private platformID = inject(PLATFORM_ID);
  isPlatformBrowser = isPlatformBrowser(this.platformID);

  libraryTab: NavbarTab = {
    url: '/your-library',
    label: 'My Games',
  };
  isAuthenticated = this.authService.isAuthenticated();
  isInClient = this.clientFeatureService.isInClient;
  showWallet = this.isAuthenticated;
  showCart = this.isInClient && this.isAuthenticated;
  showDownload = !this.isInClient;
  logoUrl = this.isInClient ? '' : 'assets/svg/ultra-games.svg';
  isSearchRouteActive$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map(() => this.isSearchRouteActive()),
    distinctUntilChanged()
  );
  tabs$ = this.isSearchRouteActive$.pipe(
    map((isSearchRouteActive) => this.getStoreTab(isSearchRouteActive)),
    map((storeTab) => (this.isInClient && this.isAuthenticated ? [storeTab, this.libraryTab] : []))
  );

  private getStoreTab(isSearchRouteActive: boolean): NavbarTab {
    return {
      url: '/store',
      label: 'Game Store',
      className: isSearchRouteActive ? 'navbar-nav__link--force-active' : '',
    };
  }

  private isSearchRouteActive(): boolean {
    return this.router.isActive('game-search', {
      paths: 'exact',
      fragment: 'ignored',
      queryParams: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
