import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular-ivy';

import { IEnvironment } from '@ultra/environment';

import { FeatureFlag, FeatureFlagService } from '../feature-flag';

// Don't force to log huge amount of data on machines with small amount of cores
const replayLimit = navigator.hardwareConcurrency <= 8 ? 2500 : 5000;

export function sentryInit(applicationName: string, env: IEnvironment) {
  const featureFlagService = new FeatureFlagService(env);
  if (featureFlagService.isEnabled(FeatureFlag.LOAD_SENTRY)) {
    init({
      // sentry endpoint
      release: `${applicationName}@0.0.0`, // todo GP-20681 CHAPTER to be added release version once the decision will be made
      dsn: env.sentryDsn,
      environment: env.name,
      ignoreErrors: [
        'Uncaught (in promise): ChunkLoadError',
        'ChunkLoadError: Loading chunk',
        // Happens when device lost internet connection, once connection will appear - Sentry sends this issue
        'Error: Non-Error exception captured with keys: error, headers, message, name, ok',
        // Generally means no response, can occur for various reasons, like request time out or no connection with BE
        /0 Unknown Error/,
        /ApolloError: You provided 'undefined' where a stream was expected/,
        // Server errors
        /502/,
        // this is an expected behaviour - inbuilt protection against infinite resize loops
        'ResizeObserver loop completed with undelivered notifications.',
      ],
      integrations: [
        // Trace Service, Directive and Decorators that leverage the tracing features to add Angular-related spans
        // to transactions. Tracks route changes and durations, while directive and decorators are tracking
        // components initializations.
        browserTracingIntegration(),
        // Session Replay helps you get to the root cause of an error or latency issue faster by providing you with
        // a video-like reproduction of what was happening in the user's browser before, during, and after the issue.
        replayIntegration({
          // A high number of DOM mutations (in a single event loop) can cause performance regressions in end-users browsers.
          mutationBreadcrumbLimit: replayLimit,
          mutationLimit: replayLimit,
        }),
      ],
      tracePropagationTargets: tracingTargets(env),
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1,
      beforeSend: (event: any) => {
        event.tags = {
          ...event.tags,
          // Custom tag to identify the current app
          application: applicationName,
        };
        return event;
      },
    });
  }
}

function tracingTargets(env: IEnvironment) {
  // The JavaScript SDK will attach the sentry-trace and baggage
  // headers to all outgoing XHR/fetch requests whose destination
  // contains a string in the list or matches a regex in the list.
  const domainPattern = env.uriGraphQL?.replace(/\//g, '\\/').replace(/\./g, '\\.');
  return [new RegExp(`^https:${domainPattern}\\/\\w+\\/v1\\/graphql`)];
}
